<template>
	<ViewLayout className="cam-side-check">
		<template v-slot:cam>
			<CapturedImage :image="image" :overlay="overlay" />
		</template>
		<template v-slot:body>
			<CamBreadcrumb :index="1" />
			<h1 v-if="$store.strings.camera_sideCheck && $store.strings.camera_sideCheckTitle">{{ $store.strings.camera_sideCheckTitle }}</h1>
			<p v-for="check of $store.strings.camera_sideCheck.split('\n')" :key="check"><span class="question-bubble">{{ check }}</span></p>
			<div class="spacer"></div>
		</template>
		<template v-slot:footer>
			<div class="uk-button-group">
				<router-link to="cam-side">
					<button class="uk-button uk-button-primary uk-button-large matomo-button-no" onclick="_paq.push(['trackEvent', 'cam_side_check', false]);">
						<i uk-icon="icon: close"></i>
					</button>
				</router-link>
				<router-link to="cam-top">
					<button class="uk-button uk-button-primary uk-button-large matomo-button-yes" onclick="_paq.push(['trackEvent', 'cam_side_check', true]);">
						<i uk-icon="icon: check"></i>
					</button>
				</router-link>
			</div>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue'
import CamBreadcrumb from '@/components/CamBreadcrumb.vue'
import CapturedImage from '@/components/CapturedImage.vue'
import Overlay from '@/assets/cam-overlay-side.svg'

export default {
	name: 'CamSideCheck',
	components: {
		ViewLayout,
		CamBreadcrumb,
		CapturedImage,
	},
	data: function() {
		return {
			image: this.$store.request.images.side,
			overlay: Overlay,
		}
	},
	mounted: function() {
		if (!this.$store.request.images.side)
			this.$router.push('/')
	},
	created: function() {
		if (
			!this.$store.request ||
			this.$store.request.cistern === null ||
			!this.$store.request.images.front ||
			!this.$store.request.images.side
		) {
			this.$router.push('/')
		}
	},
}
</script>
